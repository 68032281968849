import React from "react"
import "../styles/post.css"
import Layout from "../components/layout";
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Post({ pageContext }) {
    const { title, updatedAt, image, oldItem, slug } = pageContext.post;
    const body = pageContext.post.body.childMarkdownRemark.html;
    const description = pageContext.post.description.description;
    const imageUrl = getImage(image.gatsbyImageData);
    const ogImage  = imageUrl.images.fallback.src || '';
    
    console.log(slug);
    return (
        <Layout>
            <Seo title={title} 
                description={description.replace(/\r?\n/g, '')} 
                image={ogImage}
                slug={slug} />
            <div className="post-header">
                <h1>{title}</h1>
                {oldItem !== null?
                        <p className="post-date">{oldItem}</p>
                        :
                        <p className="post-date">{updatedAt}</p>
                }
            </div>
            <GatsbyImage 
                        image={imageUrl}  className="post-image" alt="post-cover"/>

            <div dangerouslySetInnerHTML={{ __html: body }} className="post-body" />
        </Layout>
    )
}